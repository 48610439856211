"use strict";
import { useCallback } from "react";
import { useAppDispatch } from "state/hooks";
import { addSerializedToken } from "state/user/reducer";
import { serializeToken } from "uniswap/src/utils/currency";
export function useAddUserToken() {
  const dispatch = useAppDispatch();
  return useCallback(
    (token) => {
      dispatch(addSerializedToken({ serializedToken: serializeToken(token) }));
    },
    [dispatch]
  );
}
