export const ALL_NETWORKS_LOGO = require("./logos/png/all-networks-icon.png");
export const ETHEREUM_LOGO = require("./logos/png/ethereum-logo.png");
export const OPTIMISM_LOGO = require("./logos/png/optimism-logo.png");
export const ARBITRUM_LOGO = require("./logos/png/arbitrum-logo.png");
export const BASE_LOGO = require("./logos/png/base-logo.png");
export const BNB_LOGO = require("./logos/png/bnb-logo.png");
export const POLYGON_LOGO = require("./logos/png/polygon-logo.png");
export const BLAST_LOGO = require("./logos/png/blast-logo.png");
export const AVALANCHE_LOGO = require("./logos/png/avalanche-logo.png");
export const CELO_LOGO = require("./logos/png/celo-logo.png");
export const ZORA_LOGO = require("./logos/png/zora-logo.png");
export const ZKSYNC_LOGO = require("./logos/png/zksync-logo.png");
export const ABSTRACT_LOGO = require("./logos/png/abstract-logo.png");
export const ZERO_LOGO = require("./logos/png/zero-logo.png");
export const GOERLI_LOGO = require("./logos/png/goerli-logo.png");
export const MUMBAI_LOGO = require("./logos/png/mumbai-logo.png");
export const UNISWAP_LOGO = require("./logos/png/uniswap-logo.png");
export const UNISWAP_LOGO_LARGE = require("./logos/png/uniswap-logo-large.png");
export const UNISWAP_APP_ICON = require("./logos/png/uniswap-app-icon.png");
export const ONBOARDING_QR_ETCHING_VIDEO_LIGHT = require("./videos/light-etching.mp4");
export const ONBOARDING_QR_ETCHING_VIDEO_DARK = require("./videos/dark-etching.mp4");
export const AVATARS_LIGHT = require("./misc/avatars-light.png");
export const AVATARS_DARK = require("./misc/avatars-dark.png");
export const APP_SCREENSHOT_LIGHT = require("./misc/app-screenshot-light.png");
export const APP_SCREENSHOT_DARK = require("./misc/app-screenshot-dark.png");
export const CHROME_LOGO = require("./logos/png/chrome-logo.png");
export const DOT_GRID = require("./misc/dot-grid.png");
export const UNITAGS_BANNER_VERTICAL_LIGHT = require("./graphics/unitags-banner-v-light.png");
export const UNITAGS_BANNER_VERTICAL_DARK = require("./graphics/unitags-banner-v-dark.png");
export const UNITAGS_INTRO_BANNER_LIGHT = require("./graphics/unitags-intro-banner-light.png");
export const UNITAGS_INTRO_BANNER_DARK = require("./graphics/unitags-intro-banner-dark.png");
export const DAI_LOGO = require("./logos/png/dai-logo.png");
export const USDC_LOGO = require("./logos/png/usdc-logo.png");
export const ETH_LOGO = require("./logos/png/eth-logo.png");
export const OPENSEA_LOGO = require("./logos/png/opensea-logo.png");
export const ENS_LOGO = require("./logos/png/ens-logo.png");
export const FROGGY = require("./graphics/froggy.png");
export const CEX_TRANSFER_MODAL_BG_LIGHT = require("./graphics/cex-transfer-modal-bg-light.png");
export const CEX_TRANSFER_MODAL_BG_DARK = require("./graphics/cex-transfer-modal-bg-dark.png");
export const UNITAG_DARK = require("./graphics/unitag-dark.png");
export const UNITAG_LIGHT = require("./graphics/unitag-light.png");
export const UNITAG_DARK_SMALL = require("./graphics/unitag-dark-small.png");
export const UNITAG_LIGHT_SMALL = require("./graphics/unitag-light-small.png");
export const ONBOARDING_LANDING_LIGHT = {
  ios: require(`./backgrounds/onboarding-landing-static-light.png`),
  android: require(`./backgrounds/onboarding-landing-static-light.png`)
};
export const ONBOARDING_LANDING_DARK = {
  ios: require(`./backgrounds/onboarding-landing-static-dark.png`),
  android: require(`./backgrounds/onboarding-landing-static-dark.png`)
};
export const ONBOARDING_NOTIFICATIONS_DARK = {
  ios: require(`./backgrounds/ios/notifications-dark.png`),
  android: require(`./backgrounds/android/notifications-dark.png`)
};
export const ONBOARDING_NOTIFICATIONS_LIGHT = {
  ios: require(`./backgrounds/ios/notifications-light.png`),
  android: require(`./backgrounds/android/notifications-light.png`)
};
export const FOR_CONNECTING_BACKGROUND_DARK = require("./backgrounds/for-connecting-dark.png");
export const FOR_CONNECTING_BACKGROUND_LIGHT = require("./backgrounds/for-connecting-light.png");
export const CRYPTO_PURCHASE_BACKGROUND_LIGHT = require("./backgrounds/coins-background-light.png");
export const CRYPTO_PURCHASE_BACKGROUND_DARK = require("./backgrounds/coins-background-dark.png");
export const SECURITY_SCREEN_BACKGROUND_DARK = {
  ios: require(`./backgrounds/ios/security-background-dark.png`),
  android: require(`./backgrounds/android/security-background-dark.png`)
};
export const SECURITY_SCREEN_BACKGROUND_LIGHT = {
  ios: require(`./backgrounds/ios/security-background-light.png`),
  android: require(`./backgrounds/android/security-background-light.png`)
};
export const DEAD_LUNI = require("./graphics/dead-luni.png");
