"use strict";
import { useSupportedChainId } from "constants/chains";
import { DEFAULT_ACTIVE_LIST_URLS } from "constants/lists";
import { COMMON_BASES } from "constants/routing";
import { NATIVE_CHAIN_ID } from "constants/tokens";
import forkConfig from "forkConfig";
import { useTokenListCurrency, useTokenListToken } from "hooks/TokensLegacy";
import { useAccount } from "hooks/useAccount";
import { useMemo } from "react";
import { useCombinedInactiveLists, useCombinedTokenMapFromUrls } from "state/lists/hooks";
import { useUserAddedTokens } from "state/user/userAddedTokens";
import { UNIVERSE_CHAIN_INFO } from "uniswap/src/constants/chains";
import { SafetyLevel } from "uniswap/src/data/graphql/uniswap-data-api/__generated__/types-and-hooks";
import { useCurrencyInfo as useUniswapCurrencyInfo } from "uniswap/src/features/tokens/useCurrencyInfo";
import { UniverseChainId } from "uniswap/src/types/chains";
import { buildCurrencyId } from "uniswap/src/utils/currencyId";
import { isAddress, isSameAddress } from "utilities/src/addresses";
function useTokensFromMap(tokenMap, chainId) {
  return useMemo(() => {
    if (!chainId) {
      return {};
    }
    return Object.keys(tokenMap[chainId] ?? {}).reduce((newMap, address) => {
      newMap[address] = tokenMap[chainId][address].token;
      return newMap;
    }, {});
  }, [chainId, tokenMap]);
}
export function useFallbackListTokens(chainId) {
  const fallbackListTokens = useCombinedInactiveLists();
  const tokensFromMap = useTokensFromMap(fallbackListTokens, chainId);
  const userAddedTokens = useUserAddedTokens();
  return useMemo(() => {
    return userAddedTokens.reduce(
      (tokenMap, token) => {
        tokenMap[token.address] = token;
        return tokenMap;
      },
      // must make a copy because reduce modifies the map, and we do not
      // want to make a copy in every iteration
      { ...tokensFromMap }
    );
  }, [tokensFromMap, userAddedTokens]);
}
export function useCurrency(address, chainId, skip) {
  const currencyInfo = useCurrencyInfo(address, chainId, skip);
  return currencyInfo?.currency;
}
export function useCurrencyInfo(addressOrCurrency, chainId, skip) {
  const { chainId: connectedChainId } = useAccount();
  const chainIdWithFallback = (typeof addressOrCurrency === "string" ? chainId : addressOrCurrency?.chainId) ?? connectedChainId;
  const nativeAddressWithFallback = UNIVERSE_CHAIN_INFO[chainIdWithFallback]?.nativeCurrency.address ?? UNIVERSE_CHAIN_INFO[UniverseChainId.Zero]?.nativeCurrency.address;
  const isNative = useMemo(() => checkIsNative(addressOrCurrency), [addressOrCurrency]);
  const address = useMemo(
    () => getAddress(isNative, nativeAddressWithFallback, addressOrCurrency),
    [isNative, nativeAddressWithFallback, addressOrCurrency]
  );
  const tokenListCurrency = useTokenListCurrency(address, chainId);
  const supportedChainId = useSupportedChainId(chainIdWithFallback);
  const addressWithFallback = isNative || !address ? nativeAddressWithFallback : address;
  const currencyId = buildCurrencyId(supportedChainId ?? UniverseChainId.Mainnet, addressWithFallback);
  const currencyInfo = useUniswapCurrencyInfo(currencyId, { skip: !forkConfig.uniSpecificFeaturesEnabled && skip });
  return useMemo(() => {
    const commonBase = getCommonBase(chainIdWithFallback, isNative, address);
    if (commonBase) {
      return commonBase;
    }
    if (tokenListCurrency) {
      return {
        currency: tokenListCurrency,
        currencyId,
        safetyLevel: SafetyLevel.Verified,
        logoUrl: void 0
      };
    }
    if (!currencyInfo || !addressOrCurrency || skip) {
      return void 0;
    }
    return currencyInfo;
  }, [addressOrCurrency, currencyInfo, chainIdWithFallback, isNative, address, skip, tokenListCurrency, currencyId]);
}
const checkIsNative = (addressOrCurrency) => {
  return typeof addressOrCurrency === "string" ? [NATIVE_CHAIN_ID, "native", "eth"].includes(addressOrCurrency.toLowerCase()) : addressOrCurrency?.isNative ?? false;
};
const getCommonBase = (chainId, isNative, address) => {
  if (!address || !chainId) {
    return void 0;
  }
  return COMMON_BASES[chainId]?.find(
    (base) => base.currency.isNative && isNative || base.currency.isToken && isSameAddress(base.currency.address, address)
  );
};
const getAddress = (isNative, nativeAddressWithFallback, addressOrCurrency) => {
  if (typeof addressOrCurrency === "string") {
    if (isNative) {
      return nativeAddressWithFallback;
    } else {
      return addressOrCurrency;
    }
  }
  if (addressOrCurrency) {
    if (addressOrCurrency.isNative) {
      return nativeAddressWithFallback;
    } else if (addressOrCurrency) {
      return addressOrCurrency.address;
    }
  }
  return void 0;
};
export function useToken(tokenAddress, chainId) {
  const gqlTokenListsEnabled = forkConfig.uniSpecificFeaturesEnabled;
  const formattedAddress = isAddress(tokenAddress);
  const tokenListToken = useTokenListToken(tokenAddress);
  const { chainId: connectedChainId } = useAccount();
  const currency = useCurrency(formattedAddress ? formattedAddress : void 0, chainId ?? connectedChainId);
  return useMemo(() => {
    if (!gqlTokenListsEnabled) {
      return tokenListToken;
    }
    if (currency && currency.isToken) {
      return currency;
    }
    return void 0;
  }, [currency, gqlTokenListsEnabled, tokenListToken]);
}
function useCombinedActiveList() {
  const activeTokens = useCombinedTokenMapFromUrls(DEFAULT_ACTIVE_LIST_URLS);
  return activeTokens;
}
export function useDefaultActiveTokens(chainId) {
  const defaultListTokens = useCombinedActiveList();
  const tokensFromMap = useTokensFromMap(defaultListTokens, chainId);
  const userAddedTokens = useUserAddedTokens();
  return useMemo(() => {
    return userAddedTokens.reduce(
      (tokenMap, token) => {
        tokenMap[token.address] = token;
        return tokenMap;
      },
      // must make a copy because reduce modifies the map, and we do not
      // want to make a copy in every iteration
      { ...tokensFromMap }
    );
  }, [tokensFromMap, userAddedTokens]);
}
