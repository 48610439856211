"use strict";
import Navbar from "components/NavBar/index";
import { InDevelopmentBanner } from "components/TopLevelBanners/InDevelopmentBanner";
import { MobileAppPromoBanner, useMobileAppPromoBannerEligible } from "components/TopLevelBanners/MobileAppPromoBanner";
import { UkBanner, useRenderUkBanner } from "components/TopLevelBanners/UkBanner";
import forkConfig from "forkConfig";
import { ScrollDirection, useScroll } from "hooks/useScroll";
import styled from "lib/styled-components";
import { useBag } from "nft/hooks";
import { GRID_AREAS } from "pages/App/utils/shared";
import { memo } from "react";
import { useLocation } from "react-router-dom";
import { NAV_HEIGHT } from "theme";
import { Z_INDEX } from "theme/zIndex";
import { useIsTouchDevice } from "ui/src";
const AppHeader = styled.div`
  grid-area: ${GRID_AREAS.HEADER};
  width: 100vw;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: ${Z_INDEX.sticky};
`;
const Banners = styled.div`
  position: relative;
  z-index: ${Z_INDEX.sticky};
`;
const NavOnScroll = styled.div`
  width: 100%;
  transition: transform ${({ theme }) => theme.transition.duration.slow};
  background-color: ${({ theme, $transparent }) => !$transparent && theme.surface1};
  border-bottom: ${({ theme, $transparent }) => !$transparent && `1px solid ${theme.surface3}`};
  ${({ $hide }) => $hide && `transform: translateY(-${NAV_HEIGHT}px);`}
`;
export const Header = memo(function Header2() {
  const { isScrolledDown, direction: scrollDirection } = useScroll();
  const { pathname } = useLocation();
  const isExplorePage = pathname.startsWith("/explore");
  const isBagExpanded = useBag((state) => state.bagExpanded);
  const isHeaderTransparent = !isScrolledDown && !isBagExpanded;
  const renderUkBanner = useRenderUkBanner();
  const extensionEligible = useMobileAppPromoBannerEligible();
  const isTouchDevice = useIsTouchDevice();
  return <AppHeader id="AppHeader">
    <Banners>
      {extensionEligible && <MobileAppPromoBanner />}
      {renderUkBanner && <UkBanner />}
      {forkConfig.inDevelopment && <InDevelopmentBanner />}
    </Banners>
    <NavOnScroll
      $hide={isTouchDevice && !isExplorePage && scrollDirection === ScrollDirection.DOWN}
      $transparent={isHeaderTransparent}
    ><Navbar /></NavOnScroll>
  </AppHeader>;
});
