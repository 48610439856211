"use strict";
import { NEVER_RELOAD } from "@uniswap/redux-multicall";
import { Token } from "@uniswap/sdk-core";
import { DEFAULT_LIST_OF_LISTS } from "constants/lists";
import { UNKNOWN_TOKEN_NAME, UNKNOWN_TOKEN_SYMBOL } from "constants/tokens";
import { arrayify, parseBytes32String } from "ethers/lib/utils";
import { useDefaultActiveTokens } from "hooks/Tokens";
import { useAccount } from "hooks/useAccount";
import { useBytes32TokenContract, useTokenContract } from "hooks/useContract";
import { useSingleCallResult } from "lib/hooks/multicall";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { useMemo } from "react";
import { useAppSelector } from "state/hooks";
import { useCombinedTokenMapFromUrls } from "state/lists/hooks";
import { deserializeToken } from "uniswap/src/utils/currency";
import { isAddress } from "utilities/src/addresses";
import { DEFAULT_ERC20_DECIMALS } from "utilities/src/tokens/constants";
function useCurrencyFromMap(tokens, chainId, currencyId) {
  const nativeCurrency = useNativeCurrency(chainId);
  const isNative = Boolean(nativeCurrency && currencyId?.toUpperCase() === "ETH");
  const token = useTokenFromMapOrNetwork(tokens, isNative ? void 0 : currencyId);
  if (currencyId === null || currencyId === void 0) {
    return;
  }
  const wrappedNative = nativeCurrency?.wrapped;
  if (wrappedNative?.address?.toUpperCase() === currencyId?.toUpperCase()) {
    return wrappedNative;
  }
  return isNative ? nativeCurrency : token;
}
export function useTokenListCurrency(currencyId, chainId) {
  const { chainId: connectedChainId } = useAccount();
  const tokens = useDefaultActiveTokens(chainId ?? connectedChainId);
  return useCurrencyFromMap(tokens, chainId ?? connectedChainId, currencyId);
}
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/;
function parseStringOrBytes32(str, bytes32, defaultValue) {
  return str && str.length > 0 ? str : (
    // need to check for proper bytes string and valid terminator
    bytes32 && BYTES32_REGEX.test(bytes32) && arrayify(bytes32)[31] === 0 ? parseBytes32String(bytes32) : defaultValue
  );
}
function useTokenFromActiveNetwork(tokenAddress) {
  const { chainId } = useAccount();
  const formattedAddress = isAddress(tokenAddress);
  const tokenContract = useTokenContract(formattedAddress ? formattedAddress : void 0, false);
  const tokenContractBytes32 = useBytes32TokenContract(formattedAddress ? formattedAddress : void 0, false);
  const tokenName = useSingleCallResult(tokenContract, "name", void 0, NEVER_RELOAD);
  const tokenNameBytes32 = useSingleCallResult(tokenContractBytes32, "name", void 0, NEVER_RELOAD);
  const symbol = useSingleCallResult(tokenContract, "symbol", void 0, NEVER_RELOAD);
  const symbolBytes32 = useSingleCallResult(tokenContractBytes32, "symbol", void 0, NEVER_RELOAD);
  const decimals = useSingleCallResult(tokenContract, "decimals", void 0, NEVER_RELOAD);
  const isLoading = useMemo(
    () => decimals.loading || symbol.loading || tokenName.loading,
    [decimals.loading, symbol.loading, tokenName.loading]
  );
  const parsedDecimals = useMemo(() => decimals?.result?.[0] ?? DEFAULT_ERC20_DECIMALS, [decimals.result]);
  const parsedSymbol = useMemo(
    () => parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], UNKNOWN_TOKEN_SYMBOL),
    [symbol.result, symbolBytes32.result]
  );
  const parsedName = useMemo(
    () => parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], UNKNOWN_TOKEN_NAME),
    [tokenName.result, tokenNameBytes32.result]
  );
  return useMemo(() => {
    if (typeof tokenAddress !== "string" || !formattedAddress) {
      return void 0;
    }
    if (isLoading || !chainId) {
      return null;
    }
    if (!decimals?.result?.[0] && parsedSymbol === UNKNOWN_TOKEN_SYMBOL && parsedName === UNKNOWN_TOKEN_NAME) {
      return void 0;
    }
    return new Token(chainId, formattedAddress, parsedDecimals, parsedSymbol, parsedName);
  }, [tokenAddress, chainId, formattedAddress, isLoading, decimals?.result, parsedDecimals, parsedSymbol, parsedName]);
}
function useTokenFromMapOrNetwork(tokens, tokenAddress) {
  const address = isAddress(tokenAddress);
  const token = address ? tokens[address] : void 0;
  const tokenFromNetwork = useTokenFromActiveNetwork(token ? void 0 : address ? address : void 0);
  return tokenFromNetwork ?? token;
}
export function useAllTokensMultichain() {
  const allTokensFromLists = useCombinedTokenMapFromUrls(DEFAULT_LIST_OF_LISTS);
  const userAddedTokensMap = useAppSelector(({ user: { tokens } }) => tokens);
  return useMemo(() => {
    const chainTokenMap = {};
    if (userAddedTokensMap) {
      Object.keys(userAddedTokensMap).forEach((key) => {
        const chainId = Number(key);
        const tokenMap = {};
        Object.values(userAddedTokensMap[chainId]).forEach((serializedToken) => {
          tokenMap[serializedToken.address] = deserializeToken(serializedToken);
        });
        chainTokenMap[chainId] = tokenMap;
      });
    }
    Object.keys(allTokensFromLists).forEach((key) => {
      const chainId = Number(key);
      const tokenMap = chainTokenMap[chainId] ?? {};
      Object.values(allTokensFromLists[chainId]).forEach(({ token }) => {
        tokenMap[token.address] = token;
      });
      chainTokenMap[chainId] = tokenMap;
    });
    return chainTokenMap;
  }, [userAddedTokensMap, allTokensFromLists]);
}
export function useTokenListToken(tokenAddress) {
  const { chainId } = useAccount();
  const tokens = useDefaultActiveTokens(chainId);
  return useTokenFromMapOrNetwork(tokens, tokenAddress);
}
