"use strict";
import { DEFAULT_LIST_OF_LISTS } from "constants/lists";
export const migration9 = (state) => {
  if (!state) {
    return state;
  }
  let updatedListsByUrl = state.lists?.byUrl;
  if (state.lists?.byUrl) {
    updatedListsByUrl = Object.fromEntries(
      Object.entries(state.lists.byUrl).filter(([url]) => {
        return DEFAULT_LIST_OF_LISTS.includes(url);
      })
    );
  }
  return {
    ...state,
    lists: {
      byUrl: updatedListsByUrl
    },
    _persist: {
      ...state._persist,
      version: 9
    }
  };
};
