"use strict";
import styled, { useTheme } from "lib/styled-components";
import { ConnectingViewWrapper } from "pages/Swap/Buy/shared";
import { ExternalLink } from "theme/components";
import { Flex, Text, useIsDarkMode } from "ui/src";
import { ServiceProviderLogoStyles } from "uniswap/src/features/fiatOnRamp/constants";
import { getOptionalServiceProviderLogo } from "uniswap/src/features/fiatOnRamp/utils";
import { Trans, t } from "uniswap/src/i18n";
const StyledLink = styled(ExternalLink)`
  font-weight: 535;
  color: ${({ theme }) => theme.neutral3};
`;
export function ProviderConnectedView({ closeModal, selectedServiceProvider }) {
  const isDarkMode = useIsDarkMode();
  const theme = useTheme();
  return <ConnectingViewWrapper closeModal={closeModal}><Flex alignItems="center" gap="$spacing48">
    <Flex alignItems="center" gap="$spacing24">
      <img
        style={ServiceProviderLogoStyles.uniswapLogoWrapper}
        height={120}
        src={getOptionalServiceProviderLogo(selectedServiceProvider?.logos, isDarkMode)}
        width={120}
      />
      <Flex alignItems="center" gap="$spacing8">
        <Text variant="subheading1"><Trans
          i18nKey="fiatOnRamp.completeTransactionHeader"
          values={{ serviceProvider: selectedServiceProvider.name }}
        /></Text>
        <Text variant="body2" textAlign="center" color="$neutral2"><Trans i18nKey="fiatOnRamp.continueInTab" values={{ serviceProvider: selectedServiceProvider.name }} /></Text>
      </Flex>
    </Flex>
    <Text variant="body4" textAlign="center" color="$neutral3"><Trans
      i18nKey="fiatOnRamp.disclaimer"
      values={{
        serviceProvider: selectedServiceProvider.name
      }}
      components={{
        /* FIXME: update with real URL */
        tosLink: <StyledLink color={theme.neutral3} href="https://futurehost.xyz/terms-of-service/">{t("common.termsOfService")}</StyledLink>,
        /* FIXME: update with real URL */
        privacyLink: <StyledLink color={theme.neutral3} href="https://futurehost.xyz/privacy-policy">{t("common.privacyPolicy")}</StyledLink>
      }}
    /></Text>
  </Flex></ConnectingViewWrapper>;
}
