"use strict";
import { RPC_PROVIDERS } from "constants/providers";
import { useAccount } from "hooks/useAccount";
import { useEthersProvider } from "hooks/useEthersProvider";
import useIsWindowVisible from "hooks/useIsWindowVisible";
import { atom } from "jotai";
import { useAtomValue } from "jotai/utils";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
import { UniverseChainId } from "uniswap/src/types/chains";
export const multicallUpdaterSwapChainIdAtom = atom(void 0);
const MISSING_PROVIDER = Symbol();
export const BlockNumberContext = createContext(MISSING_PROVIDER);
function useBlockNumberContext() {
  const blockNumber = useContext(BlockNumberContext);
  if (blockNumber === MISSING_PROVIDER) {
    throw new Error("BlockNumber hooks must be wrapped in a <BlockNumberProvider>");
  }
  return blockNumber;
}
export function useFastForwardBlockNumber() {
  return useBlockNumberContext().fastForward;
}
export default function useBlockNumber() {
  return useBlockNumberContext().block;
}
export function useMainnetBlockNumber() {
  return useBlockNumberContext().mainnetBlock;
}
export function BlockNumberProvider({ children }) {
  const account = useAccount();
  const multicallUpdaterSwapChainId = useAtomValue(multicallUpdaterSwapChainIdAtom);
  const multichainFlagEnabled = useFeatureFlag(FeatureFlags.MultichainUX);
  const multicallChainId = multichainFlagEnabled ? multicallUpdaterSwapChainId ?? account.chainId : account.chainId;
  const provider = useEthersProvider({ chainId: multicallChainId });
  const [{ chainId, block, mainnetBlock }, setChainBlock] = useState({});
  const activeBlock = chainId === multicallChainId ? block : void 0;
  const onChainBlock = useCallback((chainId2, block2) => {
    setChainBlock((chainBlock) => {
      if (chainBlock.chainId === chainId2) {
        if (!chainBlock.block || chainBlock.block < block2) {
          const mainnetBlock2 = chainId2 === UniverseChainId.Mainnet ? block2 : chainBlock.mainnetBlock;
          return { chainId: chainId2, block: block2, mainnetBlock: mainnetBlock2 };
        }
      } else if (chainId2 === UniverseChainId.Mainnet) {
        if (!chainBlock.mainnetBlock || chainBlock.mainnetBlock < block2) {
          return { ...chainBlock, mainnetBlock: block2 };
        }
      }
      return chainBlock;
    });
  }, []);
  const windowVisible = useIsWindowVisible();
  useEffect(() => {
    if (provider && multicallChainId && windowVisible) {
      setChainBlock((chainBlock) => {
        if (chainBlock.chainId !== multicallChainId) {
          return { chainId: multicallChainId, mainnetBlock: chainBlock.mainnetBlock };
        }
        return chainBlock;
      });
      const onBlock = (block2) => onChainBlock(multicallChainId, block2);
      provider.on("block", onBlock);
      return () => {
        provider.removeListener("block", onBlock);
      };
    }
    return;
  }, [provider, windowVisible, onChainBlock, multicallChainId]);
  useEffect(() => {
    RPC_PROVIDERS[UniverseChainId.Zero].getBlockNumber().then((block2) => onChainBlock(UniverseChainId.Zero, block2)).catch(() => void 0);
  }, [onChainBlock]);
  const value = useMemo(
    () => ({
      fastForward: (update) => {
        if (multicallChainId) {
          onChainBlock(multicallChainId, update);
        }
      },
      block: activeBlock,
      mainnetBlock
    }),
    [activeBlock, mainnetBlock, multicallChainId, onChainBlock]
  );
  return <BlockNumberContext.Provider value={value}>{children}</BlockNumberContext.Provider>;
}
