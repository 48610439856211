"use strict";
import { createReducer } from "@reduxjs/toolkit";
import { getVersionUpgrade, VersionUpgrade } from "@uniswap/token-lists";
import { DEFAULT_LIST_OF_LISTS } from "constants/lists";
import { updateVersion } from "state/global/actions";
import { acceptListUpdate, addList, fetchTokenList, removeList } from "state/lists/actions";
const NEW_LIST_STATE = {
  error: null,
  current: null,
  loadingRequestId: null,
  pendingUpdate: null
};
export const initialState = {
  lastInitializedDefaultListOfLists: DEFAULT_LIST_OF_LISTS,
  byUrl: {
    ...DEFAULT_LIST_OF_LISTS.reduce((memo, listUrl) => {
      memo[listUrl] = NEW_LIST_STATE;
      return memo;
    }, {})
  }
};
export default createReducer(
  initialState,
  (builder) => builder.addCase(fetchTokenList.pending, (state, { payload: { requestId, url } }) => {
    const current = state.byUrl[url]?.current ?? null;
    const pendingUpdate = state.byUrl[url]?.pendingUpdate ?? null;
    state.byUrl[url] = {
      current,
      pendingUpdate,
      loadingRequestId: requestId,
      error: null
    };
  }).addCase(fetchTokenList.fulfilled, (state, { payload: { requestId, tokenList, url } }) => {
    const current = state.byUrl[url]?.current;
    const loadingRequestId = state.byUrl[url]?.loadingRequestId;
    if (current) {
      const upgradeType = getVersionUpgrade(current.version, tokenList.version);
      if (upgradeType === VersionUpgrade.NONE) {
        return;
      }
      if (loadingRequestId === null || loadingRequestId === requestId) {
        state.byUrl[url] = {
          current,
          pendingUpdate: tokenList,
          loadingRequestId: null,
          error: null
        };
      }
    } else {
      state.byUrl[url] = {
        current: tokenList,
        pendingUpdate: null,
        loadingRequestId: null,
        error: null
      };
    }
  }).addCase(fetchTokenList.rejected, (state, { payload: { url, requestId, errorMessage } }) => {
    if (state.byUrl[url]?.loadingRequestId !== requestId) {
      return;
    }
    state.byUrl[url] = {
      current: state.byUrl[url].current ? state.byUrl[url].current : null,
      pendingUpdate: null,
      loadingRequestId: null,
      error: errorMessage
    };
  }).addCase(addList, (state, { payload: url }) => {
    if (!state.byUrl[url]) {
      state.byUrl[url] = NEW_LIST_STATE;
    }
  }).addCase(removeList, (state, { payload: url }) => {
    if (state.byUrl[url]) {
      delete state.byUrl[url];
    }
  }).addCase(acceptListUpdate, (state, { payload: url }) => {
    if (!state.byUrl[url]?.pendingUpdate) {
      throw new Error("accept list update called without pending update");
    }
    state.byUrl[url] = {
      ...state.byUrl[url],
      current: state.byUrl[url].pendingUpdate,
      pendingUpdate: null
    };
  }).addCase(updateVersion, (state) => {
    if (!state.lastInitializedDefaultListOfLists) {
      state.byUrl = initialState.byUrl;
    } else if (state.lastInitializedDefaultListOfLists) {
      const lastInitializedSet = state.lastInitializedDefaultListOfLists.reduce(
        (s, l) => s.add(l),
        /* @__PURE__ */ new Set()
      );
      const newListOfListsSet = DEFAULT_LIST_OF_LISTS.reduce((s, l) => s.add(l), /* @__PURE__ */ new Set());
      DEFAULT_LIST_OF_LISTS.forEach((listUrl) => {
        if (!lastInitializedSet.has(listUrl)) {
          state.byUrl[listUrl] = NEW_LIST_STATE;
        }
      });
      state.lastInitializedDefaultListOfLists.forEach((listUrl) => {
        if (!newListOfListsSet.has(listUrl)) {
          delete state.byUrl[listUrl];
        }
      });
    }
    state.lastInitializedDefaultListOfLists = DEFAULT_LIST_OF_LISTS;
  })
);
